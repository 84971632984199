<app-admin-menu-tab></app-admin-menu-tab>
<div class="scoot-card" *ngIf="settingsLoaded">
  <div class="tw-flex tw-justify-between">
    <div class="tw-w-[48%]">
      <label class="tw-text-0xl tw-m-0 tw-mb-0.75">Admin Role - Admin</label>
      <app-mutliple-tag-input [inputTags]="settings.adminRole" (onChange)="hasCurrentChange()"></app-mutliple-tag-input>
    </div>
    <div class="tw-w-[48%]">
      <label class="tw-text-0xl tw-m-0 tw-mb-0.75">Admin Role - GHA</label>
      <app-mutliple-tag-input [inputTags]="settings.adminRoleGHA"
        (onChange)="hasCurrentChange()"></app-mutliple-tag-input>
    </div>
  </div>
  <div class="tw-flex tw-justify-between tw-mt-[20px]">
    <div class="tw-w-[48%]">
      <label class="tw-text-0xl tw-m-0 tw-mb-0.75">Fare Class</label>
      <app-mutliple-tag-input [inputTags]="settings.fareClasses"
        (onChange)="hasCurrentChange()"></app-mutliple-tag-input>
    </div>
    <div class="tw-w-[20%]">
      <label class="tw-text-0xl tw-m-0 tw-mb-0.75">Allow Kiosk Standby Check-in</label>
      <app-radio-input [radioInputValue]="settings.allowStandbyCheckIn"
        (outputEvent)="settings.allowStandbyCheckIn=$event; hasCurrentChange()"></app-radio-input>
    </div>
    <div class="tw-w-[25%]">
      <label class="tw-text-0xl tw-m-0 tw-mb-0.75">Allow Kiosk Standby Station</label>
      <app-mutliple-tag-input [inputTags]="settings.allowStandbyStation" [validateNewTag]="mustBeThreeCharacters"
        (onChange)="hasCurrentChange()"></app-mutliple-tag-input>
    </div> 
  </div>
  <div class="tw-flex tw-justify-between tw-mt-[20px]">
    <div class="tw-w-[48%]">
      <label class="tw-text-0xl tw-m-0 tw-mb-0.75">Check-In Opening (Mins-SIN)</label>
      <app-number-input [numberInput]="settings.checkInOpeningTimeSingapore"
        (onChange)="settings.checkInOpeningTimeSingapore=$event; hasCurrentChange()"></app-number-input>
    </div>
    <div class="tw-w-[48%]">
      <label class="tw-text-0xl tw-m-0 tw-mb-0.75">Default Open Time</label>
      <app-number-input [numberInput]="settings.checkInOpeningTimeDefault"
        (onChange)="settings.checkInOpeningTimeDefault=$event; hasCurrentChange()"></app-number-input>
    </div>
  </div>
  <div class="tw-flex tw-justify-between tw-mt-[20px]">
    <div class="tw-w-[48%]">
      <label class="tw-text-0xl tw-m-0 tw-mb-0.75">Check-In Close Time Before Departure (Mins)</label>
      <app-number-input [numberInput]="settings.checkInCloseTimeBeforeDeparture"
        (onChange)="settings.checkInCloseTimeBeforeDeparture=$event; hasCurrentChange()"></app-number-input>
    </div>
    <div class="tw-w-[48%]">
      <label class="tw-text-0xl tw-m-0 tw-mb-0.75">Check-In Deny Time Before Departure (Mins)</label>
      <app-number-input [numberInput]="settings.checkInDenyTimeBeforeDeparture"
        (onChange)="settings.checkInDenyTimeBeforeDeparture=$event; hasCurrentChange()"></app-number-input>
    </div>
  </div>
  <div class="tw-flex tw-justify-between tw-mt-[20px]">
    <div class="tw-w-[48%]">
      <label class="tw-text-0xl tw-m-0 tw-mb-0.75">Special SSR</label>
      <app-mutliple-tag-input [inputTags]="settings.specialSSR"
        (onChange)="hasCurrentChange()"></app-mutliple-tag-input>
    </div>
    <div class="tw-w-[48%]">
      <label class="tw-text-0xl tw-m-0 tw-mb-0.75">Restricted Route List</label>
      <app-mutliple-tag-input [inputTags]="settings.restrictedRoutesList" [validateNewTag]="mustBeRouteFormat"
        (onChange)="hasCurrentChange()"></app-mutliple-tag-input>
    </div>
  </div>
  <div class="tw-flex tw-justify-between tw-mt-[20px]">
    <div class="tw-w-[48%]">
      <label class="tw-text-0xl tw-m-0 tw-mb-0.75">Hide Station Code Displayed</label>
      <app-mutliple-tag-input [inputTags]="settings.hideStationCodeDisplayed" [validateNewTag]="mustBeThreeCharacters"
        (onChange)="hasCurrentChange()"></app-mutliple-tag-input>
    </div>
    <div class="tw-w-[48%]">
      <label class="tw-text-0xl tw-m-0">Force Queue</label>     
      <div class="tw-flex tw-flex-wrap tw-justify-between">        
        <div>          
          <label class="tw-text-0xl tw-m-0 tw-mb-0.75">TR</label>
          <app-radio-input [radioInputValue]="settings.forceQueue.tr"
            (outputEvent)="settings.forceQueue.tr=$event; hasCurrentChange()"></app-radio-input>
        </div>
        <div>
          <label class="tw-text-0xl tw-m-0 tw-mb-0.75">SQ</label>
          <app-radio-input [radioInputValue]="settings.forceQueue.sq"
            (outputEvent)="settings.forceQueue.sq=$event; hasCurrentChange()"></app-radio-input>
        </div>
        <div>
          <label class="tw-text-0xl tw-m-0 tw-mb-0.75">OA</label>
          <app-radio-input [radioInputValue]="settings.forceQueue.oa"
            (outputEvent)="settings.forceQueue.oa=$event; hasCurrentChange()"></app-radio-input>
        </div>
      </div>
      <div class="tw-mt-[20px]">
        <label class="tw-text-0xl tw-m-0 tw-mb-0.75">Force Queue Station</label>
        <app-mutliple-tag-input [inputTags]="settings.forceQueue.forceQueueStation"
          [validateNewTag]="mustBeThreeCharacters" (onChange)="hasCurrentChange()"></app-mutliple-tag-input>
      </div>     

    </div>
       
  </div>
  <div class="tw-mt-[20px]">
    <label class="tw-text-0xl tw-m-0 tw-mb-0.75">Jump Seat Availability</label>
    <app-seat-availability-table [seatAvailabilityItems]="settings.jumpSeatAvailability"
      (onChange)="hasCurrentChange()"></app-seat-availability-table>
  </div>
  <div class="tw-mt-[20px]">
    <label class="tw-text-0xl tw-m-0 tw-mb-0.75">Seat Threshold Limit</label>
    <app-seat-availability-table [seatAvailabilityItems]="settings.seatThresholdLimit"
      (onChange)="hasCurrentChange()"></app-seat-availability-table>
  </div>
  <div class="tw-mt-[20px]">
    <label class="tw-text-0xl tw-m-0 tw-mb-0.75">Queue Assignment Map</label>
    <app-queue-assignment-table [queueAssignmentItems]="settings.queueAssignmentMap"
      (onChange)="hasCurrentChange()"></app-queue-assignment-table>
  </div>
</div>
<div *ngIf="showConfirmChanges">
  <div class="tw-fixed tw-top-0 tw-left-0 tw-z-20 tw-w-[100%] tw-h-[100%] tw-flex tw-justify-center tw-items-center">
    <div class="scoot-card tw-p-32 tw-text-center">
      <div class="tw-font-bold tw-text-3xl tw-mx-auto">Are you sure?</div>
      <div class="tw-mx-auto">This action cannot be undone</div>
      <div class="tw-flex tw-justify-center tw-mt-3 tw-mx-auto">
        <button class="scoot-button scoot-button--secondary tw-mr-2.5" (click)="showConfirmChanges=false">No</button>
        <button class="scoot-button scoot-button--primary" (click)="update()">Yes</button>
      </div>
    </div>
  </div>
  <div class="tw-fixed tw-top-0 tw-left-0 tw-z-10 tw-w-[100%] tw-h-[100%] tw-bg-black tw-bg-opacity-50"></div>
</div>