import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { environment } from 'src/environments/environment';
import { AdminHomeWidgetComponent } from './admin-home-widget/admin-home-widget.component';
import { AdminLoginWidgetComponent } from './admin-login-widget/admin-login-widget.component';
import { AdminSettingWidgetComponent } from './admin-setting-widget/admin-setting-widget.component';
import { AdminWidgetComponent } from './admin-widget/admin-widget.component';
import { ApiInterceptor } from './api.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CallToActionComponent } from './call-to-action/call-to-action.component';
import { ErrorWidgetComponent } from './error-widget/error-widget.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HealthDeclarationWidgetComponent } from './health-declaration-widget/health-declaration-widget.component';
import { ItineraryWidgetComponent } from './itinerary-widget/itinerary-widget.component';
import { LoginWidgetComponent } from './login-widget/login-widget.component';
import { PageLoaderComponent } from './page-loader/page-loader.component';
import { PassengerWidgetComponent } from './passenger-widget/passenger-widget.component';
import { ScootCardComponent } from './scoot-card/scoot-card.component';
import { SafetyAndSecurityWidgetComponent } from './safety-and-security-widget/safety-and-security-widget.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationWidgetComponent } from './confirmation-widget/confirmation-widget.component';
import { AlternativeEmailComponent } from './itinerary-widget/alternative-email/alternative-email.component';
import { MutlipleTagInputComponent } from './mutliple-tag-input/mutliple-tag-input.component';
import { RadioInputComponent } from './radio-input/radio-input.component';
import { AdminMenuTabComponent } from './admin-menu-tab/admin-menu-tab.component';
import { SeatAvailabilityTableComponent } from './seat-availability-table/seat-availability-table.component';
import { AdminStandbyHistoryWidgetComponent } from './admin-standby-history-widget/admin-standby-history-widget.component';
import { RangeDatePickerComponent } from './range-date-picker/range-date-picker.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { QueueNoticeDialogComponent } from './itinerary-widget/queue-notice-dialog/queue-notice-dialog.component';
import { UnhandledErrorInterceptor } from './error-widget/unhandled-error.interceptor';
import { AdminQueueComponent } from './admin-home-widget/admin-queue/admin-queue.component';
import { QueueAssignmentTableComponent } from './queue-assignment-table/queue-assignment-table.component';
import { QueueEmailDialogComponent } from './itinerary-widget/queue-email-dialog/queue-email-dialog.component';
import { ErrorWidgetAdminComponent } from './error-widget/error-widget-admin/error-widget-admin.component';
import {NgIdleKeepaliveModule} from "@ng-idle/keepalive";
import {NgOptionHighlightModule} from "@ng-select/ng-option-highlight";
import {NgxPaginationModule} from 'ngx-pagination';
import { AdminPaxLinkSettingWidgetComponent } from './admin-paxlink-setting-widget/admin-paxlink-setting-widget.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PassengerWidgetComponent,
    ErrorWidgetComponent,
    HealthDeclarationWidgetComponent,
    ItineraryWidgetComponent,
    LoginWidgetComponent,
    AdminLoginWidgetComponent,
    AdminWidgetComponent,
    AdminHomeWidgetComponent,
    PageLoaderComponent,
    AdminSettingWidgetComponent,
    CallToActionComponent,
    ScootCardComponent,
    SafetyAndSecurityWidgetComponent,
    ConfirmationWidgetComponent,
    AlternativeEmailComponent,
    MutlipleTagInputComponent,
    RadioInputComponent,
    AdminMenuTabComponent,
    SeatAvailabilityTableComponent,
    AdminStandbyHistoryWidgetComponent,
    RangeDatePickerComponent,
    NumberInputComponent,
    QueueNoticeDialogComponent,
    AdminQueueComponent,
    QueueAssignmentTableComponent,
    QueueEmailDialogComponent,
    ErrorWidgetAdminComponent,
    AdminPaxLinkSettingWidgetComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgSelectModule,
    NgbModule,
    NgxPaginationModule,
    NgOptionHighlightModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnhandledErrorInterceptor, multi: true },
    { provide: 'baseUrl', useValue: environment.baseUrl },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
