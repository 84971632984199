import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { ApplicationStateService } from './application-state.service';

export interface PaxlinkSettings {
  adminPortalRoles: { [key: string]: string[] },
  activatedStations: string[],
  activatedFlights: string[],
  enablePaxlink: boolean
}

@Injectable({
  providedIn: 'root'
})
export class PaxlinkSettingService {

  constructor(
    private httpClient: HttpClient,
    private applicationStateService: ApplicationStateService
  ) { }

  retrieve$(): Observable<PaxlinkSettings> {
    return this.httpClient.get<PaxlinkSettings>('/resource/paxlinksetting');
  }

  update$(setting: PaxlinkSettings): Observable<boolean>{
    return this.httpClient.post('/resource/paxlinksetting', setting)
    .pipe(
      map(() => true),
      catchError(errorResponse => {
        this.applicationStateService.updateApplicationError(errorResponse.error);
        return of(false);
      })
    );
  }
}
