import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminLoginService } from '../services/admin-login.service';
import { PageLoaderService } from '../services/page-loader.service';

@Component({
  selector: 'app-admin-login-widget',
  templateUrl: './admin-login-widget.component.html',
  styleUrls: ['./admin-login-widget.component.scss'],
})
export class AdminLoginWidgetComponent {
  loginForm: FormGroup;
  errorMessage: string | null = null;
  constructor(
    private router: Router,
    private adminLoginService: AdminLoginService,
    private pageLoaderService: PageLoaderService
  ) {
    this.loginForm = this.adminLoginService.loginForm;
  }

  login() {
    this.errorMessage = '';
    this.loginForm.markAllAsTouched();
    if (this.loginForm.invalid) {
      this.errorMessage =
        'Please ensure your username and password are entered correctly';
      return;
    }

    this.pageLoaderService.showPageLoader();
    this.adminLoginService
      .login$(this.loginForm.value.username, this.loginForm.value.password)
      .subscribe({
        next: (r) =>
          this.router.navigate([r ? 'kiosk/paxlink-setting' : 'kiosk/login']),
        error: (e) => {
          this.errorMessage =
            'Please ensure your username and password are entered correctly';
          this.pageLoaderService.hidePageLoader();
        },
      });
  }

  isInvalid(controlName: string): boolean {
    const control = this.loginForm.get(controlName)!;
    return !control.valid && (control.dirty || control.touched);
  }
}
