<!-- <app-admin-menu-tab></app-admin-menu-tab> -->
<div class="scoot-card">
    <div class="tw-flex tw-justify-between">
      <div class="tw-w-[48%]">
        <label class="tw-text-0xl tw-m-0 tw-mb-0.75">Activated Stations</label>
        <app-mutliple-tag-input [inputTags]="settings.activatedStations" (onChange)="hasCurrentChange()"></app-mutliple-tag-input>
      </div>
      <div class="tw-w-[48%]">
        <label class="tw-text-0xl tw-m-0 tw-mb-0.75">Activated Flights</label>
        <app-mutliple-tag-input [inputTags]="settings.activatedFlights" (onChange)="hasCurrentChange()"
          ></app-mutliple-tag-input>
      </div>
    </div>

    <div class="tw-flex tw-justify-between tw-mt-[20px]">
      <div class="tw-w-[25%]">
        <label class="tw-text-0xl tw-m-0 tw-mb-0.75">Enable Paxlink</label>
        <app-radio-input [radioInputValue]="settings.enablePaxlink"  (outputEvent)="settings.enablePaxlink=$event; hasCurrentChange()">
          ></app-radio-input>
      </div>
    </div>
  </div>

  <div *ngIf="showConfirmChanges">
    <div class="tw-fixed tw-top-0 tw-left-0 tw-z-20 tw-w-[100%] tw-h-[100%] tw-flex tw-justify-center tw-items-center">
      <div class="scoot-card tw-p-32 tw-text-center">
        <div class="tw-font-bold tw-text-3xl tw-mx-auto">Are you sure?</div>
        <div class="tw-mx-auto">This action cannot be undone</div>
        <div class="tw-flex tw-justify-center tw-mt-3 tw-mx-auto">
          <button class="scoot-button scoot-button--secondary tw-mr-2.5" (click)="showConfirmChanges=false">No</button>
          <button class="scoot-button scoot-button--primary" (click)="update()">Yes</button>
        </div>
      </div>
    </div>
    <div class="tw-fixed tw-top-0 tw-left-0 tw-z-10 tw-w-[100%] tw-h-[100%] tw-bg-black tw-bg-opacity-50"></div>
  </div>
