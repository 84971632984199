import { Component, OnInit } from '@angular/core';
import { ActivationEnd, ActivationStart, Router } from '@angular/router';
import { CallToActionConfig } from './call-to-action/call-to-action.component';
import {Idle} from "@ng-idle/core";
import {AccountService} from "./services/account.service";
import {IDLE_PERIOD_IN_SECONDS} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{

  title = 'staff-travel-check-in-web';
  showCallToAction = false;
  callToActionConfig?: CallToActionConfig;
  constructor(private router: Router, private _idle: Idle, private accountService: AccountService) {
    this._idle.setIdle(IDLE_PERIOD_IN_SECONDS);
    this.router.events.subscribe((data) => {
      if (data instanceof ActivationStart) {
        const { callToAction } = data.snapshot.data;
        this.showCallToAction = callToAction?.enabled;
        this.callToActionConfig = {
          ...callToAction
        };
      }
    });
    this._idle.watch()
  }

  ngOnInit(): void {
    // const mainURL = window.location.href;
    // if(mainURL.includes("chopemyseat-uat.flyscootca.com"))//check UAT domain
    // {
    //   const pathName = window.location.pathname;
    //   if(!pathName.includes("/qmp")) //redirect to other domain if path name != /qmp
    //     {
    //       window.location.href = 'https://chopemyseat-nonprod.flyscoot.com'+pathName;
    //     }
    // }
    //production
    const mainURL = window.location.href;
    const pathName = window.location.pathname;
    if(!pathName.includes("/kiosk")) {
      if(mainURL.includes("chopemyseat.flyscootca.com"))//check production domain
      {
        if(pathName.includes("/qmp")) {
          window.location.href = 'https://qmp.flyscootca.com'
        } else {
          window.location.href = 'https://chopemyseat.flyscoot.com' + pathName;
        }
      }
      //uat
      if(mainURL.includes("chopemyseat-uat.flyscootca.com"))//check uat domain
      {
        if(pathName.includes("/qmp")) {
          window.location.href = 'https://qmp-nonprod.flyscootca.com'
        } else {
          window.location.href = 'https://chopemyseat-nonprod.flyscoot.com' + pathName;
        }
      }
    }
    //production
    this._idle.onIdleStart.subscribe(() => {
      this.accountService.logOut();
      window.location.reload();
    });
    this._idle.onIdleEnd.subscribe(() => {
      console.log('user idle onIdleEnd')
    });
  }
}
