<div class="tw-flex tw-w-full lg:tw-container tw-mx-3.75 lg:tw-mx-37.5 tw-px-0">
  <div class="tw-flex tw-justify-start tw-w-full">
    <img
    class="tw-mt-3.75 tw-h-[50px] tw-w-[50px] lg:tw-h-[70px] lg:tw-w-[70px]"
    src="/assets/images/scoot-logo.svg" />
  </div>
  <div *ngIf="isLoggedIn" class="header-function tw-hidden lg:tw-flex lg:tw-justify-end lg:tw-w-full tw-mt-7">
    <p class="tw-float-right tw-font-bold tw-text-0.9 tw-mt-[6px]">Welcome, {{loggedInUser}}</p>
    <a routerLink="/kiosk" (click)="logout()" class="tw-font-sans tw-text-1 tw-rounded-2xl tw-ml-4 tw-bg-scoot-yellow-20 tw-p-[10px] tw-font-bold tw-h-9">
      <div>Logout</div>
    </a>
  </div>
</div>
