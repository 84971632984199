import { Injectable } from '@angular/core';
import { cloneDeep, forEach } from 'lodash';
import { map, Observable, of, tap } from 'rxjs';
import { PaxlinkSettings, PaxlinkSettingService } from '../services/paxlink-setting.service';


export interface AdminPaxlinkSettings {
  activatedStations: string[];
  activatedFlights: string[];
  enablePaxlink: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AdminPaxlinkSettingHandlerService {
  adminSettings: AdminPaxlinkSettings = {} as AdminPaxlinkSettings;
  settings: PaxlinkSettings = {} as PaxlinkSettings;
  settingsExpiry: number = 0;

  constructor(
    private settingService: PaxlinkSettingService
  ) {
    this.adminSettings.activatedStations = [];
    this.adminSettings.activatedFlights = [];
    this.adminSettings.enablePaxlink = false;
  }

  retrieve$(): Observable<AdminPaxlinkSettings> {
    const now = new Date()
    const ttl = 300000;
    if (this.settingsExpiry === 0 || this.settingsExpiry < now.getTime()) {
      this.settingsExpiry = now.getTime() + ttl;
      return this.settingService.retrieve$().pipe(
        tap(settings => this.settings = settings),
        tap(settings => this.mapSettingsToAdminSettings(settings)),
        map(() => this.adminSettings)
      );
    }

    return of(this.adminSettings);
  }

  private mapSettingsToAdminSettings(setting: PaxlinkSettings) {
    const settingClone = cloneDeep(setting);
    
    this.adminSettings.activatedStations = settingClone.activatedStations ?? [];
    this.adminSettings.activatedFlights = settingClone.activatedFlights ?? [];
    this.adminSettings.enablePaxlink = settingClone.enablePaxlink ?? false;

    }

  update$(adminSettings: AdminPaxlinkSettings): Observable<boolean> {
    const updatedSettings = this.mapAdminSettingsToSettings(adminSettings);
    this.settingsExpiry = 0;
    return this.settingService.update$(updatedSettings);
  }

  private mapAdminSettingsToSettings(adminSettings: AdminPaxlinkSettings): PaxlinkSettings {
    let clonesSettings = cloneDeep(this.settings);
    clonesSettings.activatedStations = adminSettings.activatedStations;
    clonesSettings.activatedFlights = adminSettings.activatedFlights;    
    clonesSettings.enablePaxlink = adminSettings.enablePaxlink;
    return clonesSettings;
  }
}
