import { Component, OnInit } from '@angular/core';
// import { PaxlinkSettings, PaxlinkSettingService } from '../services/paxlink-setting.service';
import { AdminPaxlinkSettingHandlerService, AdminPaxlinkSettings } from '../admin-paxlink-setting-widget/admin-paxlink-setting-handler.service';
import { cloneDeep, isEqual } from 'lodash';
import { CallToActionConfig, CallToActionHandler } from '../call-to-action/call-to-action.component';
import { CallToActionHandlerService } from '../call-to-action/call-to-action-handler.service';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
@Component({
  selector: 'app-admin-paxlink-setting-widget',
  templateUrl: './admin-paxlink-setting-widget.component.html',
  styleUrls: ['./admin-paxlink-setting-widget.component.scss']
})
export class AdminPaxLinkSettingWidgetComponent extends CallToActionHandler {
  settings: AdminPaxlinkSettings;
  currentlyAppliedSettings: AdminPaxlinkSettings;
  settingsLoaded: boolean;
  showConfirmChanges: boolean;
  constructor(
    private router: Router,
    callToActionRegistry: CallToActionHandlerService,
    private paxlinkSettingService: AdminPaxlinkSettingHandlerService,
  ) { 
    super();
    callToActionRegistry.registerHandler(this);
    this.paxlinkSettingService.retrieve$().subscribe(data => {
      this.settings = cloneDeep(data);
        this.currentlyAppliedSettings = cloneDeep(data);
        this.settingsLoaded = true;
        this.hasCurrentChange();
    });
  }
  update(){   
    this.paxlinkSettingService.update$(this.settings!)
      .subscribe(result => { 
        if(!result) {
          this.router.navigate(['error']);
        }

        this.currentlyAppliedSettings = cloneDeep(this.settings);
        this.showConfirmChanges = false;
        this.hasCurrentChange();
      });
  }


  override onPrimaryClicked$(): Observable<boolean> {
    this.showConfirmChanges = true;
    return of(false);
  }

  override onSecondaryClicked$(): Observable<boolean> {
    this.settings = cloneDeep(this.currentlyAppliedSettings);
    this.hasCurrentChange();
    return of(false);
  }
  hasCurrentChange() {
    const noChange = isEqual(this.settings, this.currentlyAppliedSettings);
    this.updateCallToActionConfig({disabled: noChange, show: true} as CallToActionConfig);
  }

  ngOnInit(): void {
    // this.axlinkSettingService.retrieve$().subscribe(data => {
    //   this.axlinkSetting = data;
    //   this.settings = cloneDeep(data);
    //     this.currentlyAppliedSettings = cloneDeep(data);
    //     this.settingsLoaded = true;
    //     this.hasCurrentChange();
    // })
  }

}
